@import url(http://fonts.googleapis.com/css?family=Roboto:400,700,500,300);

#contact {
    padding:20px 0;
}
.input-80 {
    max-width: 93%;
    width: 85%;
}
#formResponse {
    font-weight: bold;
    background-color: #FCF8E3;
    border-radius: 5px;
    padding: 10px;
    color: #5C441B;
}
.formUnfilled {
    display: none;
}
label.error {
    color: red;
    padding: 2px 0;
    margin-top: 2px;
    margin-bottom: -10px;
}
.warning {
    color: red;
}
/* Manual Code  */
.form-horizontal .control-label {
    width: 18%;
    max-width: 50%;
    text-align: right;
}

.form-horizontal .control-group{
    display: flex;
    margin-top: 15px;
}

.form-horizontal .controls {
    margin-left: 60px;
    width: 100%;
}
input[type="text"]{
    height: 25px;
}
textarea {
    height: 120px;
}

.alert-error {
    background-color: #f2dede;
    border-color: #eed3d7;
    color: #b94a48;
}
.alert{
    color: #c09853;
}

.btn{
    &.btn-warning{
        max-width: 200px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 0px;
    }
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #468847;
    padding: 10px;
    margin-bottom: 10px;
}
#recaptcha_widget_div{
    float: left;
    margin-top: -45px;
}

body{
    background: #e6e6e6;
    font-family: 'Roboto', sans-serif;
}

footer{
    margin-top: 20px;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.vstup-do-eshopu{
    display: table;
    margin: auto;
    text-align: center;
    color: black;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.controls{
    text-align: center;
}

.controls button{
    max-width: 200px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0px;
}

.download>li{
    list-style-type: none;
}

.download>li:before{
    //background: url('../img/pdf.png') no-repeat;
    content: "\f1c1";
    font-family: "fontAwesome";
    color: red;
    margin-right: 10px;
    //padding-left: 20px;
}

.download a{
    color: #032d48;
}

form textarea,
form input{
    border-radius: 0px !important;
    padding: 10px !important;
}

h3{
    font-size: 18px;
}

footer{
    background: #484647;
}

.footer-ul{
    padding-left: 0px;
}

.footer-ul>li{
    list-style-type: none;
}

.footer-ul>li>a{
    color: white;
}

.footer-ul>li>a:hover{
    color: #ed7737;
}

.container{
    background: white;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 30px;
    box-shadow: black 4px 6px 20px;
    -webkit-box-shadow: black 4px 6px 20px;
    -moz-box-shadow: black 4px 6px 20px;
}

h1{
    border-left: 10px solid #d5662a;
    padding-left: 20px;
}

.strong{
    font-weight: bold;
}

.contact{
    width: 250px;
}

.contact i{
    color: black;
    margin-right: 10px;
}

.contact>p:nth-of-type(2)>a{
    background: #ededed;
    padding: 10px;
    font-weight: bold;
}

.contact>p:nth-of-type(2)>a:nth-of-type(2){
    color: #032d48;
}

.contact p:first-of-type{
    font-weight: bold;
    color: #ed7737;
    margin-bottom: 20px;
}

.contact a:focus,
.contact a{
    color: black;
}

.contact a:hover .strong,
.contact a:hover{
    color: #ed7737 !important;
    text-decoration: none;
    transition:0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

hr{
    margin-bottom: 10px !important;
    border-color: #ed7737;
}

a:focus{
    text-decoration: none;
}

a{
    outline: 0px !important;
}

.eshop-btn:focus,
.eshop-btn{
    background: #032d48;
    color: white;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    max-width: 255px;
    display: block;
    text-align: center;
    font-size: 20px;
    border:4px solid #032d48;
}

.eshop-btn:hover{
    text-decoration: none;
    color: #032d48;
    background: white;
    transition:0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.margin-top-20{
    margin-top: 20px;
}

.padding-left-0{
    padding-left: 0px !important;
}

.flexslider{
    border: 4px solid #ed7737;
}

.eshop-bottom{
    background: #032d48;
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    border: 5px solid #032d48;
    margin-top: 10px;
}

.eshop-bottom:hover{
    background: white;
}

.flex-control-nav li{
    margin: 0 4px !important;
}

.flex-control-paging li a{
    height: 15px !important;
    width: 15px !important;
    background: #e17a1e;
    border: 2px solid #e17a1e;
    box-shadow: none !important;
}

.flex-control-paging li a:hover{
    background: white !important;
}

.flex-control-paging li a.flex-active{
    background: white !important;
    border: 2px solid #ed7737;
}

.top-menu>li{
    display: inline-flex;
    width: 20%;
}

.top-menu>li>a{
    width: 100%;
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    background:#454344;
}

.width-29{
    width: 29% !important;
}

.width-71{
    width: 71% !important;
}

.nav>li>a{
    background: #ed7737;
    color: white;
    border-right: 20px solid #ed7737;
    margin-bottom: 1px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.nav>li>a:hover{
    border-color: #d5662a;
    background: #ed7737;
}

a:hover{
    text-decoration: none;
    transition:0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.nav>li.active>a{
    border-color: #d5662a;
}

.padding-right-0{
    padding-right: 0px !important;
}

.item-pageuvodni-stranka{
    //margin-top: 350px;
}

.top-ul{
    margin-bottom: 20px;
    padding-left: 0px;
    background: #454344;
    max-width: 825px;
}

.top-ul>li{
    display: table-cell;
    list-style-type: none;
    width: 2%;
}

.top-ul>li:last-of-type>a{
    border: 0px;
}

.top-ul>li>a{
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    color: white;
    display: block;
    margin: auto;
    border-right: 1px solid white;
}

.top-ul>li>a:hover{
    color: #ed7737;
}

[alt="Ostatní sortiment"],
[alt="Palmové svíčky Rentex"],
[alt="Parafínové svíčky Rentex"],
[alt="Svíčky Rentex"]{
    margin-top: 10px;
    margin-bottom: 10px;
}

.bezpecnost>div{
    margin-bottom: 10px;
}

.bezpecnost p{
    margin-top: 40px;
}

@media (max-width: 1200px){
    .item-pageuvodni-stranka{
        //margin-top: 300px;
    }

    .eshop-btn{
        max-width: 205px;
    }

    .top-ul{
        width: 660px;
    }
}

@media (max-width: 992px){
    .nav>li:nth-of-type(4)>a,
    .nav>li:nth-of-type(3)>a,
    .nav>li:nth-of-type(2)>a,
    .nav>li:nth-of-type(1)>a{
        font-size: 0px;
    }

    .nav>li:nth-of-type(1)>a:before{
        content:"OstatnĂ­";
        font-size: 14px;
    }

    .nav>li:nth-of-type(2)>a:before{
        content:"Ke staĹľenĂ­";
        font-size: 14px;
    }

    .nav>li:nth-of-type(3)>a:before{
        content:"NĂˇvody";
        font-size: 14px;
    }

    .nav>li:nth-of-type(4)>a:before{
        content:"BezpeÄŤnost";
        font-size: 14px;
    }

    .item-pageuvodni-stranka{
        //margin-top: 260px;
    }

    .eshop-btn{
        max-width: 150px;
    }

    .top-ul{
        width: 495px;
    }

    .contact{
        width: auto;
    }

    .contact>p:nth-of-type(2)>a{
        display:block;
    }

    .contact>p:nth-of-type(2)>a:first-of-type{
        margin-bottom: 10px;
    }

    .nav>li>a{
        padding-top: 26px !important;
        padding-bottom: 26px !important;
    }

}

@media (max-width: 767px){
    .parafinove-svicky img,
    .esencialni-oleje img,
    .bezpecnost img{
        display: block;
        margin: auto;
    }

    .bezpecnost p{
        margin-top: 10px;
    }

    .item-pageuvodni-stranka{
        //margin-top: 0px;
    }

    .nav>li>a{
        padding-top: 18px !important;
        padding-bottom: 18px !important;
    }


    .nav>li:nth-of-type(4)>a,
    .nav>li:nth-of-type(3)>a,
    .nav>li:nth-of-type(2)>a,
    .nav>li:nth-of-type(1)>a{
        font-size: 14px;
    }

    .nav>li:nth-of-type(2)>a:before,
    .nav>li:nth-of-type(3)>a:before,
    .nav>li:nth-of-type(4)>a:before,
    .nav>li:nth-of-type(1)>a:before{
        display: none;
    }

    .top-ul{
        margin-top: 10px;
        width: 100%;
    }

    .item-pageuvod  ni-stranka{
        margin-top: 390px;
    }

    .eshop-btn{
        float: none !important;
        margin: auto;
        margin-top: 10px;
        max-width: 100%;
    }

    .width-71{
        width: 100% !important;
    }

    .width-29{
        width: 100% !important;
    }

    .flex-control-paging{
        display: none;
    }

    .padding-left-0{
        padding: 0px !important;
    }

    [alt="Rentex logo"]{
        display: block;
        margin: auto;
        margin-bottom: 10px;
    }
    [alt="OstatnĂ­ sortiment"],
    [alt="PalmovĂ© sviÄŤky Rentex"],
    [alt="ParafĂ­novĂ© sviÄŤky Rentex"],
    [alt="SvĂ­ÄŤky Rentex"]{
        float: none !important;
        display: block;
        margin: auto;
    }
    body{
        background: none;
    }

    .container{
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media (max-width: 570px){
    .top-ul>li>a{
        height: 70px;
    }
}

@media (max-width: 450px){
    .top-ul>li>a{
        border-right: 0px;
        height: auto;
    }

    .top-ul>li{
        display: block;
        width: 100%;
        border-bottom: 1px solid white;
    }
}

input[type="text"]{
    height: 35px !important;
}