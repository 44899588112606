#carousel-index {
    .owl-stage-outer {
        border: 4px solid #ed7737;
    }
    .owl-controls {
        margin-top: 1rem;
        .owl-dots {
            text-align: center;
            .owl-dot {
                display: inline-block;
                margin: 0px 5px;
                span {
                    height: 15px !important;
                    width: 15px !important;
                    transition: .2s;
                    border: 2px solid #e17a1e;
                    box-shadow: none !important;
                    display: block;
                    border-radius: 50%;
                }
                &.active {
                    span {
                        background: #e17a1e;
                        transition: .3s;
                    }
                }
            }
        }
    }
}